// flow
import React, { useEffect, useState, memo } from "react"
import isEqual from "lodash/isEqual"
import type { SubscriptionType } from "@lesmills/gatsby-theme-common"
import type { UpdatePaymentPageType } from "../../types/UpdatePaymentPageType"
import type { ChangeSubscriptionPageType } from "../../types/ChangeSubscriptionPageType"
import type { AllLayoutsType, UserType } from "@lesmills/gatsby-theme-common"

import {
  ROUTES,
  getParamsFromURL,
  LoadingIndicator,
  NotificationToast,
  isEmpty,
  formatPlusTaxText,
} from "@lesmills/gatsby-theme-common"

import Container from "../Container"
import ChangeSubscriptionType from "./ChangeSubscriptionType"
import ChangeSubscriptionSummary from "./ChangeSubscriptionSummary"
import { checkStandardSubscriptionCountry } from "../../utils/LookupLocationUtil"
import { getPaymentInfo } from "../../utils/payment"

type Props = {|
  availableSubscriptions: Array<SubscriptionType>,
  user: UserType,
  dataPrismic: ChangeSubscriptionPageType,
  updatePaymentData: UpdatePaymentPageType,
  layoutData: AllLayoutsType,
  lang: string,
|}

const ChangeSubscription = ({
  availableSubscriptions,
  dataPrismic,
  updatePaymentData,
  lang,
  user,
  layoutData = {},
}: Props) => {
  const { back_to_my_account = {}, title = {}, body = [] } = dataPrismic || {}
  const [loading, setLoading] = useState(true)
  const [errors, setErrors] = useState({})
  const [userData, setUser] = useState({})
  const step = getParamsFromURL("step")
  const isSummary = step === "summary"
  const { primary = {} } = body[2] || {}
  const { back_to_select_subscription = {} } = primary
  const currentSubscription = user.lmodSubscription || {}
  // https://lesmillsinternational.atlassian.net/browse/LA-1221
  // The price of subscription in US and CA is not included tax
  // Add "plus Tax" right after price in US and CA
  const isNotIncludedTax = checkStandardSubscriptionCountry(user.addressCountry)
  const { plus_tax_label = {} } = layoutData

  useEffect(() => {
    // Loading indicator should be showed until user was fetched
    if (user && !isEmpty(user)) {
      setLoading(false)
      setUser(user)
    }
  }, [user])

  const currentPaymentMethod = getPaymentInfo(userData)

  return (
    <Container
      title={title.text}
      backLink={{
        name: isSummary
          ? back_to_select_subscription.text
          : back_to_my_account.text,
        url: isSummary
          ? ROUTES(lang).CHANGE_SUBSCRIPTION
          : ROUTES(lang).CUSTOMER_ACCOUNT,
      }}
      classNames={{
        wrapper: " max-w-599",
      }}
    >
      {loading && <LoadingIndicator />}
      {Object.keys(errors).length > 0 && (
        <NotificationToast
          children={<p>{errors.message}</p>}
          showHideIcon
          handleHideToast={() => setErrors({})}
          type={errors.type}
          classNames={{
            wrapper: " mt-20",
          }}
        />
      )}
      {isSummary ? (
        <ChangeSubscriptionSummary
          previousSubscription={currentSubscription}
          data={dataPrismic}
          updatePaymentData={updatePaymentData}
          paymentMethod={currentPaymentMethod}
          updateUser={setUser}
          lang={lang}
          layoutData={layoutData}
          user={userData}
          isNotIncludedTax={isNotIncludedTax}
          plusTaxText={plus_tax_label.text}
        />
      ) : (
        <ChangeSubscriptionType
          currentSubscription={currentSubscription}
          availableSubscriptions={availableSubscriptions}
          data={dataPrismic}
          lang={lang}
          layoutData={layoutData}
          isNotIncludedTax={isNotIncludedTax}
          plusTaxText={formatPlusTaxText(layoutData)}
        />
      )}
    </Container>
  )
}

export default memo(ChangeSubscription, (prevProps, nextProps) => {
  return isEqual(prevProps.user, nextProps.user)
})
