import React from "react"
import { navigate, graphql } from "gatsby"

import type { ChangeSubscriptionPageType } from "../types/ChangeSubscriptionPageType"
import type { UpdatePaymentPageType } from "../types/UpdatePaymentPageType"
import type { UserType, LayoutType } from "@lesmills/gatsby-theme-common"

import {
  renderSEO,
  Layout,
  PrivateRoute,
  ROUTES,
  isAffiliateUser,
  CHARGIFY_SCRIPT,
} from "@lesmills/gatsby-theme-common"

import { isFailedSubscription } from "../utils/payment"
import { withPreview } from "gatsby-source-prismic"

import ChangeSubscription from "../components/ChangeSubscription"

type Props = {|
  pageContext: {
    lang: string,
  },
  data: {
    primicLayout: LayoutType,
    prismicChangeSubscriptionPage: ChangeSubscriptionPageType,
    prismicUpdatePaymentDetailsPage: UpdatePaymentPageType,
  },
|}

const ChangeSubscriptionPage = (props: Props) => {
  const { pageContext, data } = props || {}

  if (!data) return null

  const lang = pageContext.unPublishedLang || pageContext.lang
  const {
    prismicLayout = {},
    prismicChangeSubscriptionPage = {},
    prismicUpdatePaymentDetailsPage = {},
  } = data
  const layout = prismicLayout.data || {}

  const pageContent = prismicChangeSubscriptionPage.data || {}
  const updatePaymentData = prismicUpdatePaymentDetailsPage.data || {}
  const scriptsChangeSubscriptionPage = [CHARGIFY_SCRIPT]

  const renderChangeSubscriptionPage = ({
    user = {},
    checkingSession,
  }: UserType) => {
    const lmodSubscription = user.lmodSubscription

    // LA-1339: Use both offer_tune_id and offer_id to indicate affiliate user
    if (
      isAffiliateUser(lmodSubscription) ||
      isFailedSubscription(lmodSubscription)
    ) {
      navigate(ROUTES(lang).CUSTOMER_ACCOUNT)
      return null
    }

    return (
      <Layout data={layout} lang={lang} user={user} isLoading={checkingSession}>
        {renderSEO(
          pageContent,
          ROUTES(lang).CHANGE_SUBSCRIPTION,
          lang,
          scriptsChangeSubscriptionPage
        )}
        <ChangeSubscription
          user={user}
          dataPrismic={pageContent}
          updatePaymentData={updatePaymentData}
          layoutData={layout}
          lang={lang}
        />
      </Layout>
    )
  }

  return (
    <PrivateRoute
      component={renderChangeSubscriptionPage}
      {...props}
      lang={lang}
    />
  )
}

export const query = graphql`
  query($lang: String) {
    prismicLayout(lang: { eq: $lang }) {
      data {
        if_applicable {
          text
        }
        plus_tax_label {
          text
        }
        sign_in_button {
          raw
          text
        }
        sign_out_button {
          raw
          text
        }
        logo {
          alt
          url
        }
        lmod_gu_err {
          text
        }
        ...LayoutUnits
        ...LayoutPaymentForm
        body {
          ...LayoutBodyNavigation
        }
        body1 {
          ...LayoutBody1Navigation
        }
        body2 {
          ...LayoutBody2Months
        }
        body3 {
          ...LayoutBody3Countries
        }
      }
    }
    prismicChangeSubscriptionPage(lang: { eq: $lang }) {
      ...ChangeSubscriptionPage
    }
    prismicUpdatePaymentDetailsPage(lang: { eq: $lang }) {
      ...UpdatePaymentDetailsPage
    }
  }
`

export default withPreview(ChangeSubscriptionPage)
