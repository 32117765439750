import React, { useState, useEffect, memo } from "react"
import isEqual from "lodash/isEqual"
import { API, graphqlOperation } from "aws-amplify"

import type {
  SubscriptionType,
  AllLayoutsType,
  UserType,
} from "@lesmills/gatsby-theme-common"

import type { UpdatePaymentPageType } from "../../types/UpdatePaymentPageType"
import type { ChangeSubscriptionPageType } from "../../types/ChangeSubscriptionPageType"
import type { PaymentMethodType } from "../../types/PaymentMethodType"

import {
  Button,
  getLocalStorage,
  NEW_SUBSCRIPTION_KEY,
  ROUTES,
  convertCentsToEuro,
  formatIntervalUnit,
  client,
  setLocalStorage,
  formatDateTime,
  NotificationToast,
  handleErrorWithPrismic,
  SENTRY_ACTIONS,
  DATETIME_FORMAT,
  CURRENCIES,
  isResellerCustomerUser,
} from "@lesmills/gatsby-theme-common"

import InformationWrapper from "../InformationWrapper"
import InformationRow from "../InformationRow"
import PaymentMethod from "../PaymentMethod"
import UpdatePaymentDetail from "../UpdatePaymentDetail"
import { changeSubscriptionChargifyJs } from "../../graphql/changeSubscriptionChargifyJs"
import { navigate } from "gatsby"
import UpdateLabel from "../UpdateLabel"

type Props = {|
  previousSubscription: SubscriptionType,
  data: ChangeSubscriptionPageType,
  updatePaymentData: UpdatePaymentPageType,
  paymentMethod: PaymentMethodType,
  updateUser: () => void | Promise<void>,
  layoutData?: AllLayoutsType,
  user: UserType,
  isNotIncludedTax?: Boolean,
  plusTaxText: String,
|}

const ChangeSubscriptionSummary = ({
  previousSubscription,
  data,
  updatePaymentData,
  paymentMethod,
  updateUser,
  lang,
  layoutData = {},
  user = {},
  isNotIncludedTax = false,
  plusTaxText = "",
}: Props) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [isOpenedUpdatePayment, setIsOpenedUpdatePayment] = useState(false)
  const [responseStatus, setResponseStatus] = useState({})
  const {
    confirm_button_label = {},
    body = [],
    change_subscription_error = {},
  } = data || {}
  const { primary = {}, items = [] } = body[2] || {}

  const {
    summary_title = {},
    update_payment_method = {},
    payment_note_with_trial = { raw: [] },
    payment_note_without_trial = { text: "" },
  } = primary
  const [
    previousSubscriptionLabel = { item_label: {} },
    newSubscriptionLabel = { item_label: {} },
    priceLabel = { item_label: {} },
    paymentMethodLabel = { item_label: {} },
  ] = items
  const {
    name,
    product_handle,
    product_price_point,
    currency,
  } = getLocalStorage(NEW_SUBSCRIPTION_KEY)
  const currencySymbol = CURRENCIES[currency]

  const { price_in_cents } = product_price_point || {}
  const unit = formatIntervalUnit(product_price_point, layoutData)
  const price = convertCentsToEuro(price_in_cents, currency)
  // https://lesmillsinternational.atlassian.net/browse/LA-1221
  // Add "plus Tax" right after price in US and CA
  plusTaxText = isNotIncludedTax ? `(${plusTaxText})` : ""

  useEffect(() => {
    client()
  }, [])

  const handleChangeSubscriptionType = async err => {
    if (err) {
      handleCardErrors(err)
      return
    }

    try {
      const { errors } = await API.graphql(
        graphqlOperation(changeSubscriptionChargifyJs, {
          product_handle: product_handle,
        })
      )

      setIsProcessing(false)
      if (errors && errors.length > 0) {
        handleCardErrors(errors, SENTRY_ACTIONS.CHANGE_SUBSCRIPTION_CHARGIFY_JS)
        return
      }

      setLocalStorage(NEW_SUBSCRIPTION_KEY, {
        isChangedSubscriptionSuccess: true,
      })
      navigate(ROUTES(lang).CUSTOMER_ACCOUNT)
    } catch (errorResponse) {
      setIsProcessing(false)
      handleCardErrors(
        errorResponse.errors || [],
        SENTRY_ACTIONS.CHANGE_SUBSCRIPTION_CHARGIFY_JS
      )
      return
    }
  }

  const handleCardErrors = (err, action) => {
    setIsProcessing(false)
    handleErrorWithPrismic(
      err,
      change_subscription_error.text,
      setResponseStatus,
      data,
      action
    )
  }

  const renderPaymentNoteWithTrial = () => (
    <>
      {payment_note_with_trial.raw.map((note, index) => (
        <p className="mt-5" key={index + "-" + "note"}>
          {note.text
            .replace(
              "@billing_date",
              formatDateTime(
                previousSubscription.current_period_ends_at,
                DATETIME_FORMAT.default,
                layoutData
              )
            )
            .replace("@price", `${currencySymbol}${price}${plusTaxText}`)
            .replace("@subscription", name)}
        </p>
      ))}
    </>
  )

  const renderPaymentNotes = () => (
    <div
      className="font-base-light md:text-2lg md:leading-7none text-gray-800 text-3xs leading-3normal mt-4 md:mt-25 mb-25 md:mb-30"
      data-cy="payment-note"
    >
      <>
        {user?.isEntitledToFreeTrial ? (
          renderPaymentNoteWithTrial()
        ) : (
          <p className="mt-5">
            {payment_note_without_trial.text
              .replace("@price", `${currencySymbol}${price}${plusTaxText}`)
              .replace("@subscription", name)}
          </p>
        )}
      </>
    </div>
  )

  const handleSubmit = () => {
    // form validator
    setIsProcessing(true)
    handleChangeSubscriptionType()
  }

  return (
    <>
      {Object.keys(responseStatus).length > 0 && (
        <NotificationToast
          children={<p>{responseStatus.message}</p>}
          showHideIcon
          handleHideToast={() => setResponseStatus({})}
          type={responseStatus.type}
          classNames={{
            wrapper: " mt-20",
          }}
        />
      )}
      <InformationWrapper
        title={summary_title.text}
        classNames={{ wrapper: " md:mt-41 mt-20" }}
        testId="summary-container"
      >
        <InformationRow
          label={previousSubscriptionLabel.item_label.text}
          value={
            previousSubscription.product && previousSubscription.product.name
          }
          testId="previous-subscription"
        />
        <InformationRow
          label={newSubscriptionLabel.item_label.text}
          value={`${name} Subscription`}
          testId="new-subscription"
        />
        <InformationRow
          label={priceLabel.item_label.text}
          value={`${currencySymbol}${price}/${unit}${plusTaxText}`}
          testId="price-subscription"
        />
        {paymentMethod && (
          <div className="flex justify-between items-baseline relative">
            <InformationRow
              label={paymentMethodLabel.item_label.text}
              value={<PaymentMethod paymentMethod={paymentMethod} />}
              testId="payment-method"
            />
            <Button
              className="underline text-3xs leading-2normal md:text-2lg font-base-light md:leading-7none text-gray-800 absolute right-0 mt-20 md:mt-25"
              handleOnClick={() => setIsOpenedUpdatePayment(true)}
              testId="update-payment-btn"
            >
              <UpdateLabel label={update_payment_method.text} />
            </Button>
          </div>
        )}
        {renderPaymentNotes()}
        <Button
          className="w-full btn uppercase btn-primary py-3 mb-10"
          disabled={isProcessing}
          submitting={isProcessing}
          handleOnClick={() => handleSubmit()}
          testId="confirm-btn"
        >
          {confirm_button_label.text}
        </Button>
        {isOpenedUpdatePayment && (
          <UpdatePaymentDetail
            isModal
            handleDismiss={() => {
              setIsOpenedUpdatePayment(false)
            }}
            prismicData={updatePaymentData}
            newSubscriptionPrice={price}
            updateUser={updateUser}
            lang={lang}
            user={user}
            layoutData={layoutData}
            isResellerCustomerUser={isResellerCustomerUser(user)}
          />
        )}
      </InformationWrapper>
    </>
  )
}

export default memo(ChangeSubscriptionSummary, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.user, nextProps.user) &&
    isEqual(prevProps.paymentMethod, nextProps.paymentMethod)
  )
})
